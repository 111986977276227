<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isFormOn"
          persistent
          width="500"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              color="primary"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
              @click="initCurrentForm"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              {{ isUpdate ? '編集' : '新規商品' }}
            </v-card-title>

            <v-card-text>
              <v-select
                v-show="!isUpdate"
                v-model="currentFormVendibleType"
                :items="vendibleTypes"
                item-text="locale"
                item-value="value"
                :disabled="isUpdate"
                label="タイプ"
                :menu-props="{ auto: true, offsetY: true }"
              />

              <div class="d-flex">
                <v-text-field
                  v-model="currentForm.name"
                  label="名前"
                  :error="!currentForm.name"
                />

                <v-slide-x-transition>
                  <v-select
                    v-show="currentFormVendibleType === 'Nomination'"
                    v-model="currentForm.color"
                    :items="colors"
                    label="色"
                    :error="!currentForm.color"
                    style="width: 48px;"
                    class="ml-4"
                    :menu-props="{ auto: true, offsetY: true }"
                  >
                    <template #selection="{ item: color }">
                      <v-avatar
                        size="16"
                        :color="color"
                      />
                    </template>

                    <template #item="{ item: color }">
                      <v-avatar
                        size="16"
                        :color="color"
                      />
                    </template>
                  </v-select>
                </v-slide-x-transition>

                <v-slide-x-transition>
                  <div
                    v-show="currentFormVendibleType === 'Course'"
                    class="d-flex flex-column align-center"
                  >
                    <div class="d-flex align-center flex-wrap">
                      <v-text-field
                        v-show="currentFormVendibleType === 'Course'"
                        v-model.number="currentFormCourseTime.hours"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        min="0"
                        max="24"
                        suffix="時間"
                        :disabled="isFreeTime"
                        :error="!isFreeTime && !(currentForm.effectiveDurationSeconds > 0)"
                        style="width: 60px;"
                        class="ml-4"
                        @change="currentForm.effectiveDurationSeconds = currentFormCourseTimeSeconds"
                      />
                      <v-text-field
                        v-show="currentFormVendibleType === 'Course'"
                        v-model.number="currentFormCourseTime.minutes"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        min="0"
                        max="59"
                        suffix="分"
                        :disabled="isFreeTime"
                        :error="!isFreeTime && !(currentForm.effectiveDurationSeconds > 0)"
                        style="width: 60px;"
                        class="ml-4"
                        @change="currentForm.effectiveDurationSeconds = currentFormCourseTimeSeconds"
                      />
                    </div>

                    <v-checkbox
                      v-show="currentFormVendibleType === 'Course'"
                      v-model="isFreeTime"
                      label="フリータイム"
                      :on-icon="icons.mdiTimerSandFull"
                      :off-icon="icons.mdiTimerSandFull"
                      :ripple="false"
                      color="primary"
                      hide-details
                      dense
                      class="ma-0 pa-0"
                    />
                  </div>
                </v-slide-x-transition>
              </div>

              <v-expand-transition>
                <yona-has-many-v-select
                  v-show="currentFormVendibleType === 'Course'"
                  v-model="currentForm.passiveCourseExtensionsAttributes"
                  parent-name="extendee"
                  :parent-id="currentForm.id"
                  :children="courses"
                  child-name="extender"
                  child-type="course"
                  label="延長候補"
                />
              </v-expand-transition>

              <v-text-field
                v-model.number="currentForm.sellingPrice"
                label="販売価格"
                inputmode="numeric"
                pattern="[0-9]*"
                prefix="¥"
              />

              <yona-has-many-v-select
                v-model="currentForm.vendibleTaggingsAttributes"
                parent-name="vendible"
                :parent-id="currentForm.id"
                :children="vendibleTags"
                child-name="vendibleTag"
                label="商品タグ"
              />

              <v-expand-transition>
                <v-switch
                  v-show="currentFormVendibleType === 'Nomination'"
                  v-model="isVendingContributionTrigger"
                  :input-value="isVendingContributionTrigger"
                  :true-value="true"
                  :false-value="false"
                  :append-icon="icons.mdiCashMarker"
                  style="width: 70px"
                />
              </v-expand-transition>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="!isValid || isSubmitting"
                :loading="isSubmitting"
                :ripple="false"
                @click="submit"
              >
                {{ isUpdate ? '更新' : '作成' }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :ripple="false"
                @click="resetForm"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <Menu
        :vendibles="vendibles"
        :vendible-tags="vendibleTags"
        :no-cursor-pointer="true"
        :sortable="true"
        @click:row="initCurrentForm($event); isFormOn = true;"
      >
        <template #action="{ item }">
          <span class="d-flex align-center justify-end">
            <v-btn
              icon
              :ripple="false"
            >
              <v-icon small>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>

            <yona-edit-dialog
              title="削除の確認"
              btn-color="error"
              save-text="同意の上で削除"
              @save="destroy(item.id, item.attributes.vendibleType)"
            >
              <template #append-outer-display-name>
                <v-btn
                  icon
                  :ripple="false"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </template>

              <template #input>
                <span>
                  削除を実行してもよろしいですか。
                </span>
              </template>
            </yona-edit-dialog>
          </span>
        </template>
      </Menu>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  getCurrentInstance,
  watch,
} from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiPlus,
  mdiPencilOutline,
  mdiTimerSandFull,
  mdiCashMarker,
} from '@mdi/js'
import { findIndex, each, filter } from 'lodash'
import {
  hoursToSeconds,
  minutesToSeconds,
  secondsToHours,
  secondsToMinutes,
} from 'date-fns'
import VendibleApi from '@/api/waiter/Vendible'
import VendibleTagApi from '@/api/waiter/VendibleTag'
import ItemApi from '@/api/waiter/Item'
import NominationApi from '@/api/waiter/Nomination'
import CourseApi from '@/api/waiter/Course'
import useCurrentData from '@/views/composable/useCurrentData'
import useValidation from '@/views/composable/useValidation'
import Menu from '@/views/components/vendible/Menu.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'
import YonaHasManyVSelect from '@/views/components/util/YonaHasManyVSelect.vue'

export default {
  components: {
    Menu,
    YonaEditDialog,
    YonaHasManyVSelect,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const { currentClub } = useCurrentData()
    const { isValidId } = useValidation()

    const isLoading = ref(false)
    const isSubmitting = ref(false)
    const isFormOn = ref(false)
    const isFreeTime = ref(false)
    const isVendingContributionTrigger = ref(false)
    const vendibles = ref([])
    const vendibleTags = ref([])
    const currentFormVendibleType = ref('Item')
    const currentFormCourseTime = reactive({ hours: null, minutes: null })
    const currentForm = ref({})
    const apis = {
      Item: ItemApi,
      Nomination: NominationApi,
      Course: CourseApi,
    }

    const vendibleTypes = computed(() => {
      return [
        { value: 'Item', locale: 'アイテム' },
        { value: 'Course', locale: currentClub.value.courseAlias },
        { value: 'Nomination', locale: currentClub.value.nominationAlias },
      ]
    })

    const currentFormCourseTimeSeconds = computed(() => {
      const { hours, minutes } = currentFormCourseTime

      return hoursToSeconds(hours) + minutesToSeconds(minutes)
    })

    const isUpdate = computed(() => {
      return isValidId(currentForm.value.id)
    })

    const isValid = computed(() => {
      const {
        name,
        sellingPrice,
        color,
        effectiveDurationSeconds,
      } = currentForm.value

      const isValidVendible = !!name

      let isValidNomination = true
      if (currentFormVendibleType.value === 'Nomination') {
        isValidNomination = !!color
      }

      let isValidCourse = true
      if (currentFormVendibleType.value === 'Course') {
        if (isFreeTime.value) isValidCourse = effectiveDurationSeconds === null
        else isValidCourse = effectiveDurationSeconds > 0
      }

      return isValidVendible && isValidNomination && isValidCourse && currentFormVendibleType.value
    })

    const courses = computed(() => {
      return filter(vendibles.value, o => o.attributes.vendibleType === 'Course')
    })

    watch(() => isFreeTime.value, (newVal, _) => {
      if (newVal) {
        currentFormCourseTime.hours = null
        currentFormCourseTime.minutes = null
        currentForm.value.effectiveDurationSeconds = null
      }
    })

    watch(() => isVendingContributionTrigger.value, (newVal, _) => {
      if (currentForm.value.vendingContributionTriggerAttributes?.id) {
        // eslint-disable-next-line no-underscore-dangle
        currentForm.value.vendingContributionTriggerAttributes._destroy = !newVal
      } else {
        currentForm.value.vendingContributionTriggerAttributes = newVal ? { id: null } : {}
      }
    })

    const initVendibleTaggingsAttributes = (vendibleTaggingsData = []) => {
      currentForm.value.vendibleTaggingsAttributes = []

      each(vendibleTaggingsData, tagging => {
        const { vendibleId, vendibleTagId } = tagging.attributes

        const obj = {
          id: tagging.id,
          vendibleId,
          vendibleTagId,
        }

        // eslint-disable-next-line no-underscore-dangle
        if (obj.id) obj._destroy = false

        currentForm.value.vendibleTaggingsAttributes.push(obj)
      })
    }

    const initPassiveCourseExtensionsAttributes = (passiveCourseExtensionsData = []) => {
      currentForm.value.passiveCourseExtensionsAttributes = []

      each(passiveCourseExtensionsData, extension => {
        const { extendeeId, extenderId } = extension.attributes

        const obj = {
          id: extension.id,
          extendeeId,
          extenderId,
        }

        // eslint-disable-next-line no-underscore-dangle
        if (obj.id) obj._destroy = false

        currentForm.value.passiveCourseExtensionsAttributes.push(obj)
      })
    }

    const initVendingContributionTriggerAttributes = vendingContributionTriggerData => {
      currentForm.value.vendingContributionTriggerAttributes = {}

      if (!vendingContributionTriggerData) return

      const { triggerableType, triggerableId } = vendingContributionTriggerData.attributes

      currentForm.value.vendingContributionTriggerAttributes = {
        id: vendingContributionTriggerData.id,
        triggerableType,
        triggerableId,
        _destroy: false,
      }

      isVendingContributionTrigger.value = true
    }

    const initCurrentForm = (vendible = null) => {
      currentForm.value = {}

      const {
        name,
        sellingPrice,
        color,
        effectiveDurationSeconds,
        vendibleType,
      } = { ...vendible.attributes }

      currentForm.value = {
        id: vendible.id,
        name,
        sellingPrice,
        color,
        effectiveDurationSeconds,
      }

      currentFormVendibleType.value = vendibleType || 'Item'

      if (effectiveDurationSeconds) {
        currentFormCourseTime.hours = secondsToHours(Number(effectiveDurationSeconds))

        let minutes = secondsToMinutes(Number(effectiveDurationSeconds))
        if (minutes >= 60) minutes -= 60
        currentFormCourseTime.minutes = minutes
      }
      if (currentForm.value.id && !effectiveDurationSeconds) isFreeTime.value = true

      initVendibleTaggingsAttributes(vendible.attributes?.vendibleTaggings?.data)
      initPassiveCourseExtensionsAttributes(vendible.attributes?.passiveCourseExtensions?.data)
      initVendingContributionTriggerAttributes(vendible.attributes?.vendingContributionTrigger?.data)
    }
    const resetForm = () => {
      currentFormVendibleType.value = 'Item'
      currentForm.value = {}
      currentFormCourseTime.hours = null
      currentFormCourseTime.minutes = null
      isFormOn.value = false
      isFreeTime.value = false
      isVendingContributionTrigger.value = false
    }

    const submit = async () => {
      if (!isValid.value) return

      isSubmitting.value = true

      const fnVerb = isUpdate.value ? 'update' : 'create'

      const res = await apis[currentFormVendibleType.value][`${fnVerb}${currentFormVendibleType.value}`](currentForm.value)

      if (res?.data) {
        const vendible = res.data[currentFormVendibleType.value.toLowerCase()].data

        if (isUpdate.value) {
          const vendibleIdx = findIndex(
            vendibles.value,
            o => Number(o.id) === Number(vendible.id) && o.attributes.vendibleType === vendible.attributes.vendibleType,
          )
          vendibles.value.splice(vendibleIdx, 1, vendible)
        } else {
          vendibles.value.push(vendible)
        }

        vm.$toast.success(isUpdate.value ? 'メニューを更新しました' : 'メニューに追加しました')
      }

      resetForm()

      isSubmitting.value = false
    }

    const destroy = async (id, vendibleType) => {
      isSubmitting.value = true

      const res = await apis[vendibleType][`delete${vendibleType}`](id).catch(err => {
        vm.$toast.error(err.message)
      })

      if (res) {
        const vendibleIdx = findIndex(
          vendibles.value,
          o => Number(o.id) === Number(id) && o.attributes.vendibleType === vendibleType,
        )
        vendibles.value.splice(vendibleIdx, 1)

        vm.$toast.success('商品を削除しました')
      }

      isSubmitting.value = false
    }

    const getVendibles = async () => {
      isLoading.value = true

      const res = await VendibleApi.getVendibles()

      if (res?.data) {
        vendibles.value = [...res.data.vendibles.data]
      }

      isLoading.value = false
    }

    const getVendibleTags = async () => {
      const res = await VendibleTagApi.getVendibleTags()

      if (res?.data) {
        vendibleTags.value = [...res.data.vendibleTags.data]
      }
    }

    getVendibles()
    getVendibleTags()

    return {
      // data
      isLoading,
      isSubmitting,
      isFormOn,
      isFreeTime,
      isVendingContributionTrigger,
      vendibles,
      vendibleTags,
      currentFormVendibleType,
      currentFormCourseTime,
      currentForm,
      colors: [
        'primary',
        'accent',
        'secondary',
        'success',
        'info',
        'warning',
        'error',
      ],

      // computed
      vendibleTypes,
      currentFormCourseTimeSeconds,
      isUpdate,
      isValid,
      courses,

      // methods
      initCurrentForm,
      resetForm,
      submit,
      destroy,

      icons: {
        mdiTrashCanOutline,
        mdiPlus,
        mdiPencilOutline,
        mdiTimerSandFull,
        mdiCashMarker,
      },
    }
  },
}
</script>
